












import Display from '@/utilities/Display'

export default {
  name: 'OutsideContainer',
  props: {
    bannerImg: {
      type: String,
      required: false,
    },
  },
  computed: {
    bannerImgHeight() {
      return Display.mobile(this.$vuetify) ? 250 : 400
    },
  },
}
