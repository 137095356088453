








import OutsideContainer from '@/components/Containers/OutsideContainer.vue'

export default {
  name: 'Logout',
  components: {
    OutsideContainer,
  },
  beforeMount() {
    localStorage.tk = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InBpY3MtdXNlci0wMSIsInVzZSI6ImlkZW50aXR5In0.eyJpc3MiOiJodHRwczovL2ZpbGVzLnNpbXBseS5waWNzL2p3dCIsImVtYWlsIjoiamF2YWpoYXdrQGdtYWlsLmNvbSIsImZhbWlseV9uYW1lIjoiQ2hyaXN0ZW5zZW4iLCJnaXZlbl9uYW1lIjoiUm9iIiwidXNlciI6IjFjNGJmMjhhLTZhM2QtNGNiNi04ZDcyLWViZjgzYTE2MDRkOSIsInVzZXJuYW1lIjoiamF2YWpoYXdrQGdtYWlsLmNvbSIsImp0aSI6IjZmM2RmYWU2LWFiNzEtNDkwMS04MDQ3LWUwNTJiNDFlYmIzZCIsImlhdCI6MTYwNzY1OTY5NSwiZXhwIjoxNjA3NjYzMjk1fQ.Y-tYxrKC2NgcqJ7CLM510zzwUOn1tv1HagYe-l1flamLh4nwAUJKtKt5ECoLZwx1mbNI0e2VkJ8uXzcgFziGHxXfrB3JmqFT2963dk9zT-TOybpAJuZV34olXx48idNuh640pkIZYISf5emngGjMNmJBR7M36qjfT5h26JaHwXiVYLU8W3xmugJwBH7I6ba7KQ5EtI6sfixub4-7CMxqZxZaXiudyhU4RmjXoT8qphgVpRICJ4croQz2qj8_h3u-HbjuirTvGG99MlWUgvqazIfKXz-t4WE2oaRKhMImnOAkLvHXZhZikaKXcJbfYoQbFGnaxhs8mK8KPxwDL6aAaA'
    localStorage.rt = ''
  },
}
